<template>
  <div class="transfer" />
</template>

<script>
export default {
  mounted () {
    window.location.href = '/';
  },
};
</script>
